import { GameTeammatesV2 } from '../../api/creators/getGamingTeammates'
import { GameCategories } from '../../common/enums/game-categories'

export function useGameCard() {
  function getTeammatesByCategoryV2(
    key: string,
    gameTeammates: GameTeammatesV2,
  ) {
    switch (key) {
      case GameCategories.valorant:
        return gameTeammates.valorantCompetitiveTeammates
      case GameCategories.csgo:
        return gameTeammates.leagueCompetitiveTeammates
      case GameCategories.fortnite:
        return gameTeammates.fortniteCompetitiveTeammates
      case GameCategories.tft:
        return gameTeammates.tftCompetitiveTeammates
      default:
        return gameTeammates.leagueCompetitiveTeammates
    }
  }

  function getTeammatesByCategoryAndMode(
    key: string,
    gameTeammates: GameTeammatesV2,
    mode?: string,
  ) {
    switch (key) {
      case GameCategories.valorant:
        if (mode === 'social') {
          return gameTeammates?.valorantSocialTeammates
        }
        return gameTeammates?.valorantCompetitiveTeammates
      case GameCategories.leagueOfLegends:
        if (mode === 'social') {
          return gameTeammates?.leagueSocialTeammates
        }
        return gameTeammates?.leagueCompetitiveTeammates
      case GameCategories.fortnite:
        if (mode === 'social') {
          return gameTeammates?.fortniteSocialTeammates
        } else if (mode === 'events') {
          return gameTeammates?.fortniteEventTeammates
        }
        return gameTeammates?.fortniteCompetitiveTeammates
      case GameCategories.tft:
        if (mode === 'social') {
          return gameTeammates?.tftSocialTeammates
        }
        return gameTeammates?.tftCompetitiveTeammates
      default:
        return gameTeammates?.leagueCompetitiveTeammates
    }
  }

  return {
    getTeammatesByCategoryAndMode,
    getTeammatesByCategoryV2,
  }
}
