import React from 'react'
import Image from 'next/image'

type Props = {
  imageUrl?: string
  name?: string
}

export default function ChampionsItem({ imageUrl, name }: Props) {
  return (
    <div className={'flex flex-row items-center gap-2 py-2 px-2'}>
      <div className={'relative h-6 w-6 rounded-full'}>
        <Image
          src={imageUrl ?? process.env.NEXT_PUBLIC_DEFAULT_AVATAR_URL!}
          className={'rounded-full object-cover'}
          fill={true}
          alt={'champion'}
        />
      </div>

      <p className={'text-white text-secondary-normal'}>{name ?? ''}</p>
    </div>
  )
}
