import React from 'react'
import Image from 'next/image'

type Props = {
  avatar?: string
  size?: number
}

export default function GameCardInfoAvatars({ avatar, size = 5 }: Props) {
  return (
    <div
      className={`relative w-${size} h-${size} border border-fizz-1000 rounded-full overflow-hidden bg-fizz-1000`}
    >
      <Image
        src={avatar ?? process.env.NEXT_PUBLIC_DEFAULT_AVATAR_URL!}
        className={'object-cover rounded-full'}
        fill={true}
        alt={'Avatar Image'}
      />
    </div>
  )
}
