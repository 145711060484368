import { authFetch } from '../../common/networking/auth-fetch'
import { ProductResponse } from '../../types/products/product-response'
import { SkinsPaginationResponse } from '../../types/products/skins-pagination-response'

export async function getProducts(): Promise<ProductResponse[]> {
  const response = await authFetch().get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/products?type=gift card`,
  )
  return await response.json()
}

export async function getProductCategories(): Promise<string[]> {
  const response = await authFetch().get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/products/categories`,
  )
  return await response.json()
}

export async function getProductByCategoriesAndSlug(
  gamekey: string,
  slug: string,
): Promise<ProductResponse> {
  const response = await authFetch().get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/products/categories/${gamekey}/seo-slugs/${slug}`,
  )
  return await response.json()
}

export async function getProductStreamer(
  gamekey: string,
): Promise<ProductResponse[]> {
  const response = await authFetch().get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/products/streamers?gameKey=${gamekey}`,
  )
  return await response.json()
}

export async function getSkinById(
  accessToken: any,
  pageParam: number,
  productId: number,
  q: string,
): Promise<SkinsPaginationResponse> {
  let url = `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/skins/products/${productId}`

  const queryParams = []
  if (pageParam !== null && pageParam !== undefined) {
    queryParams.push(`page=${pageParam}`)
  }

  if (q !== null && q !== undefined && q.trim() !== '') {
    queryParams.push(`q=${q}`)
  }

  if (queryParams.length) {
    url += `?${queryParams.join('&')}`
  }

  const response = await authFetch(accessToken).get(url)
  return await response.json()
}
