import React from 'react'

type Props = {
  text: string
  isSelected?: boolean
}

export default function UnderLineText({ text, isSelected }: Props) {
  return (
    <div
      className={`under-line-text ${isSelected ? 'under-line-selected text-bung-400' : 'text-white'} text-body-bold `}
    >
      {text} {/* 修正: 使用變量而不是字面量字符串 */}
    </div>
  )
}
