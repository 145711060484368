import React from 'react'
import GameCardInfoAvatars from './GameCardInfoAvatars'
import { CategoryResponse } from '../../../types/categories/responses/category-response'
import { UserResponse } from '../../../types/users/user-response'

type Props = {
  category: CategoryResponse
  enabled?: boolean
  numberOfTeammates?: number
  recentRequests?: string
  gameTeammates?: UserResponse[]
}

export default function GameCardInfo({
  enabled,
  numberOfTeammates,
  recentRequests,
  gameTeammates,
}: Props) {
  return (
    <div
      className={
        'flex flex-row rounded-[200px] bg-[#05060866] py-0.5 px-2 shrink-0 text-white'
      }
    >
      {enabled ? (
        <div className={'flex flex-row items-center'}>
          <div className={'flex flex-row items-center'}>
            {gameTeammates?.slice(0, 3)?.map((teammate, index) => (
              <div key={index} className={index !== 0 ? '-ml-2.5' : ''}>
                <GameCardInfoAvatars avatar={teammate.avatar?.imageUrl} />
              </div>
            ))}
          </div>

          {numberOfTeammates && (
            <div className={'flex flex-row gap-0.5 ml-1'}>
              <p className={'text-white text-xs-normal w-fit text-nowrap'}>
                {numberOfTeammates}
              </p>

              <p className={'text-white text-xs-normal w-fit text-nowrap'}>
                teammates
              </p>
            </div>
          )}

          {numberOfTeammates && recentRequests && (
            <span className={'mx-0.5'}>&bull;</span>
          )}

          {recentRequests && (
            <div className={'flex flex-row gap-0.5'}>
              <p className={'text-white text-xs-normal text-nowrap w-fit'}>
                {recentRequests}
              </p>

              <p className={'text-white text-xs-normal text-nowrap w-fit'}>
                recent
              </p>

              <p className={'text-white text-xs-normal text-nowrap w-fit'}>
                sessions
              </p>
            </div>
          )}
        </div>
      ) : (
        <div className={'flex flex-col '}>
          <div className={'flex flex-row'}>
            <p className={'text-color-gray4 text-body-medium text-xs-normal'}>
              Coming soon
            </p>
          </div>
        </div>
      )}
    </div>
  )
}
