import React, { useState } from 'react'
import Image from 'next/image'
import { CategoryResponse } from '../../../types/categories/responses/category-response'
import UnderLineText from './underline/UnderLineText'
import GameCardInfo from './GameCardInfo'
import { GameTeammatesV2 } from '../../../api/creators/getGamingTeammates'
import { useGameCard } from '../../../hooks/games/useGameCard'

type Props = {
  category: CategoryResponse
  isSelected: boolean
  enabled: boolean
  index?: number
  gameTeammates: GameTeammatesV2
}

export default function GameCardV4({
  category,
  isSelected,
  enabled = false,
  index = 0,
  gameTeammates,
}: Props) {
  const [isHovered, setIsHovered] = useState(false)
  const title = getCategoryTitle(category)
  const cardStyle = enabled ? 'card-style-enabled' : 'card-style-disabled'
  const { getTeammatesByCategoryV2 } = useGameCard()
  const teammates = getTeammatesByCategoryV2(category.key, gameTeammates)

  const cardClass =
    isSelected || isHovered ? 'selected-game-card' : 'select-game-card'

  function getCategoryTitle(category: CategoryResponse) {
    if (category.key === 'fifa') {
      return 'EA Sports FC 24'
    } else if (category.key === 'call-of-duty-warzone') {
      return 'Call of Duty: Warzone 2.0'
    } else {
      return category.name
    }
  }
  return (
    <div
      className={
        'relative h-[210px] w-[360px] rounded-xl mr-1.5 flex flex-col items-center shrink-0 group'
      }
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className={`relative rounded-xl overflow-hidden w-full h-[170px] ${cardClass} ${cardStyle}`}
      >
        <div
          className={
            'w-full h-full bg-gradient-to-t from-black absolute opacity-80 top-0 left-0'
          }
        />

        <div
          className={
            'relative border-3 overflow-hidden rounded-xl h-[165px] border-[#0D0D13]'
          }
        >
          <div
            className={`shine-overlay ${isSelected ? 'shine-overlay-active ' : ''}`}
          />

          <Image
            src={
              category.landscapeImageAsset?.imageUrl ??
              category.imageAsset?.imageUrl ??
              process.env.NEXT_PUBLIC_DEFAULT_IMAGE_URL!
            }
            className={`object-cover ${isSelected ? 'image-transform-active' : 'image-transform'} -z-10`}
            alt={`${category.name}`}
            fill={true}
            priority={index === 0 || index === 1}
          />
        </div>

        {category?.logoImageAsset?.imageUrl && (
          <div
            className={
              'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-fit self-center mb-3'
            }
          >
            <Image
              src={category?.logoImageAsset?.imageUrl}
              className={`object-contain transition-transform duration-500 ${isSelected ? 'image-transform-active' : 'image-transform'} z-10`}
              alt={`${category.name}`}
              height={61}
              width={200}
              priority={index === 0 || index === 1}
            />
          </div>
        )}
      </div>

      <div className={'mt-2'} />

      <UnderLineText text={title} isSelected={isSelected || isHovered} />

      <div
        className={
          'absolute bottom-12 left-1/2 -translate-x-1/2 self-center w-fit'
        }
      >
        <GameCardInfo
          category={category}
          recentRequests={category.recentRequests}
          enabled={enabled}
          numberOfTeammates={teammates?.onlineTeammatesCount ?? 0}
          gameTeammates={teammates?.teammates}
        />
      </div>
    </div>
  )
}
