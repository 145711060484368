import React from 'react'
import { useCreateTotalAmount } from '../../hooks/checkout/useCreateTotalAmount'
import { isNil } from '../../common/utils/common.utils'
import { useSession } from 'next-auth/react'
import { SessionStatusEnum } from '../../common/enums/session-status.enum'
import { useRecoilState } from 'recoil'
import { defaultCurrencyState } from '../../common/atoms/atoms'
import { formatAmountWithCurrency } from '../../common/utils/currency.utils'
import YellowCrownIcon from '../../public/static/crown-yellow.png'
import Image from 'next/image'

export default function OrderTotalDetails() {
  const { data: total } = useCreateTotalAmount()
  const { status } = useSession()
  const [currency] = useRecoilState(defaultCurrencyState)

  if (isNil(total) || status === SessionStatusEnum.UNAUTHENTICATED) {
    return null
  }

  return (
    <div className='text-white mb-4 rounded-lg w-full text-body-normal'>
      <div className='flex justify-between mb-4'>
        <span className={'text-body-semibold'}>Subtotal</span>
        <span>{formatAmountWithCurrency(total!.amount, currency, 2)}</span>
      </div>

      {!isNil(total?.proMembershipDiscount) &&
        (total?.proMembershipDiscount ?? 0) > 0 && (
          <div className='flex justify-between mb-4'>
            <div className={'flex flex-row gap-2'}>
              <span className={'text-body-semibold'}>Loss protection 2x</span>{' '}
              <Image
                src={YellowCrownIcon}
                alt={'crown'}
                className={'object-contain'}
                width={16}
                height={16}
              />
            </div>
          </div>
        )}

      {!isNil(total?.proMembershipDiscount) &&
        (total?.proMembershipDiscount ?? 0) > 0 && (
          <div className='flex justify-between mb-4'>
            <div className={'flex flex-row gap-2'}>
              <span className={'text-body-semibold'}>TapIn Pro Discount</span>{' '}
              <Image
                src={YellowCrownIcon}
                alt={'crown'}
                className={'object-contain'}
                width={16}
                height={16}
              />
            </div>
            <span className={'text-gnar-300'}>
              -
              {formatAmountWithCurrency(
                total!.proMembershipDiscount,
                currency,
                2,
              )}
            </span>
          </div>
        )}

      {!isNil(total?.freeGamesUsage) && (total?.freeGamesUsage ?? 0) > 0 && (
        <div className='flex justify-between mb-4'>
          <span className={'text-body-semibold'}>Free Games</span>
          <span className={'text-gnar-300'}>x{total?.freeGamesUsage}</span>
        </div>
      )}

      {!isNil(total?.loyaltyDiscountAmount) &&
        (total?.loyaltyDiscountAmount ?? 0) > 0 && (
          <div className='flex justify-between mb-4'>
            <span className={'text-body-semibold'}>Loyalty Program</span>
            <span className={'text-gnar-300'}>
              -
              {formatAmountWithCurrency(
                total!.loyaltyDiscountAmount,
                currency,
                2,
              )}
            </span>
          </div>
        )}

      {!isNil(total?.quantityDiscountAmount) &&
        (total?.quantityDiscountAmount ?? 0) > 0 && (
          <div className='flex justify-between mb-4'>
            <span className={'text-body-semibold'}>Bundle Discount</span>
            <span className={'text-gnar-300'}>
              -
              {formatAmountWithCurrency(
                total!.quantityDiscountAmount,
                currency,
                2,
              )}
            </span>
          </div>
        )}

      {total?.creditAmount && (
        <div className='flex justify-between mb-4'>
          <span className={'text-body-semibold'}>Tapin Credit</span>
          <span className={'text-gnar-300'}>
            -{formatAmountWithCurrency(total!.creditAmount, currency, 2)}
          </span>
        </div>
      )}

      {!isNil(total?.discountSaved) && (total?.discountSaved ?? 0) > 0 && (
        <div className='flex justify-between mb-4'>
          <span className={'text-body-semibold'}>Promotion</span>
          <span className={'text-gnar-300'}>
            -{formatAmountWithCurrency(total!.discountSaved, currency, 2)}
          </span>
        </div>
      )}

      {!isNil(total?.serviceFee) && (total?.serviceFee ?? 0) > 0 && (
        <div className='flex justify-between mb-4 items-center'>
          <p className={'text-body-semibold'}>Processing Fee</p>
          <div
            className={'flex flex-col items-end text-body-normal text-fizz-0'}
          >
            <p>{formatAmountWithCurrency(total!.serviceFee, currency, 2)}</p>
            {/*<p className={'text-xs-normal'}>(not charged by Tapin)</p>*/}
          </div>
        </div>
      )}

      {(total?.potentialServiceFee ?? 0) > 0 && (
        <div className='flex justify-between mb-4'>
          <div className={'flex flex-row gap-2'}>
            <span className={'text-body-semibold'}>Processing Fee</span>{' '}
            <Image
              src={YellowCrownIcon}
              alt={'crown'}
              className={'object-contain'}
              width={16}
              height={16}
            />
          </div>
          <span className={'ml-auto text-fizz-0 line-through'}>
            {formatAmountWithCurrency(total!.potentialServiceFee, currency, 2)}
          </span>

          <span className={'text-white ml-2'}>FREE</span>
        </div>
      )}
    </div>
  )
}
