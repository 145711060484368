import { CategoryPaginationResponse } from '../../types/categories/responses/category-pagination-response'
import { usePromoCodes } from '../../hooks/promos/usePromoCodes'
import { useTags } from '../../hooks/tags/useTags'
import { useSavedGame } from '../../hooks/cache/useSavedGame'
import React, { useContext, useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import {
  enableVoiceChatOffToggleButtonState,
  errorToastState,
  inGameAccountFromState,
  isOpenCompensateModalState,
  isOpenInGameAccountModalState,
  isOpenLimitedTimeOfferModalState,
  selectedProductState,
} from '../../common/atoms/checkout'
import { useInGameAccounts } from '../../hooks/accounts/useInGameAccounts'
import { useUser } from '../../hooks/users/useUser'
import { useSession } from 'next-auth/react'
import HomePageHeader from '../booking/HomePageHeader'
import { SessionStatusEnum } from '../../common/enums/session-status.enum'
import { ProductModeEnum } from '../../common/enums/product-mode.enum'
import dynamic from 'next/dynamic'
import {
  activeTeamState,
  isOpenCoachingState,
  isOpenLPShieldModalState,
  isOpenModeModalState,
  selectedGameState,
} from '../../common/atoms/atoms'
import { useGetMarketingOffers } from '../../hooks/marketing/useGetMarketingOffers'
import { useGetGiftingMessages } from '../../hooks/tips/useGetGiftingMessages'
import { useIntercom } from 'react-use-intercom'
import { CategoryResponse } from '../../types/categories/responses/category-response'
import { BenefitSection } from '../products/details/BenefitSection'
import { FeatureSection } from '../products/details/FeatureSection'
import { HowItWorkSection } from '../products/details/HowItWorkSection'
import { ReviewSection } from '../products/details/ReviewSection'
import FAQSection from '../how-it-work/FAQ/FAQSection'
import Divider from '../divider/Divider'
import InGameAccountSectionV2 from '../booking/InGameAccountSectionV2'
import { DescriptionSection } from '../products/details/DescriptionSection'
import { useRouter } from 'next/router'
import HomePusherRequests from '../pusher/HomePusherRequests'
import useTippingMessageToast from '../../hooks/tips/useTippingMessageToast'
import { toast } from 'react-toastify'
import { isMobile } from 'react-device-detect'
import { GameTeammatesV2 } from '../../api/creators/getGamingTeammates'
import GameSelectorV3 from '../games/selector/GameSelectorV3'
import { isNil } from '../../common/utils/common.utils'
import { lobbyUrl } from '../../common/urls/homeUrl'
import { useGetActiveTeam } from '../../hooks/teams/useGetActiveTeam'
import { UID_COOKIE } from '../../lib/constants'
import Cookies from 'js-cookie'
import { useQueryClient } from '@tanstack/react-query'
import { activeTeam } from '../../common/react-query/react-query-keys'
import { GameCategories } from '../../common/enums/game-categories'
import { useUpdateUser } from '../../hooks/users/useUpdateUser'
import {
  StatsigContext,
  useUpdateUser as updateUserForStatsig,
} from 'statsig-react'
import useProductMode, {
  selectedProductModeState,
} from './mode-selector/useProductMode'
import PlayMode from './mode/PlayMode'
// import AccountMode from './mode/AccountMode'
import AccountInfoSection from './mode/account/AccountInfoSection'

// const ModeSelector = dynamic(() => import('./mode-selector/ModeSelector'), {
//   ssr: true,
// })

// const FortniteLeaderBanner = dynamic(
//   () => import('../products/banner/leaderboard/FortniteLeaderBanner'),
//   {
//     ssr: true,
//   },
// )

const HomeDeviceData = dynamic(() => import('../pusher/HomeDeviceData'), {
  ssr: true,
})
const GuestNotification = dynamic(() => import('./GuestNotification'), {
  ssr: true,
})

const InGameAccountModal = dynamic(
  () => import('../booking/modal/ingame/InGameAccountModal'),
  {
    ssr: true,
  },
)
const CompensateModal = dynamic(
  () => import('../booking/modal/CompensateModal'),
  {
    ssr: true,
  },
)
const LimitedTimeOfferModal = dynamic(
  () => import('../checkout/modals/LimitedTimeOfferModal'),
  {
    ssr: true,
  },
)

const EarthAnimation = dynamic(() => import('../animations/Earth'), {
  ssr: true,
})

const CoachingInfoModal = dynamic(
  () => import('../products/CoachingInfoModal'),
  {
    ssr: true,
  },
)
const ProductModeInfoModal = dynamic(
  () => import('../products/ProductModeInfoModal'),
  {
    ssr: true,
  },
)
const LPShieldModal = dynamic(() => import('../products/LPShieldModal'), {
  ssr: true,
})

type Props = {
  categories: CategoryPaginationResponse
  gameTeammates: GameTeammatesV2
  variant?: any
  title?: string
  description?: string
  seoCategory?: CategoryResponse
  seoProductMode?: ProductModeEnum
  readSavedGame?: boolean
  isHome?: boolean
}

export function HomeModel({
  categories,
  gameTeammates,
  title,
  description,
  seoCategory,
  seoProductMode,
  readSavedGame = true,
  isHome = false,
}: Props) {
  // States.
  usePromoCodes()
  useTags()
  useSavedGame(categories, seoCategory?.key, seoProductMode, readSavedGame)
  useGetMarketingOffers()
  useGetGiftingMessages()
  useTippingMessageToast()

  // Recoil states.
  const [isOpenInGameAccountModal, setInGameAccountModalOpen] = useRecoilState(
    isOpenInGameAccountModalState,
  )
  const { modes } = useProductMode()
  const [selectedProductMode] = useRecoilState(selectedProductModeState)

  const { profiles, refetchProfiles } = useInGameAccounts(true)
  const [selectedProduct] = useRecoilState(selectedProductState)
  const [, setInGameAccountFrom] = useRecoilState(inGameAccountFromState)
  const [isOpenCompensateModal] = useRecoilState(isOpenCompensateModalState)
  const [isOpenLimitedTimeOfferModal] = useRecoilState(
    isOpenLimitedTimeOfferModalState,
  )
  const [isOpenCoaching] = useRecoilState(isOpenCoachingState)
  const [isOpenModeModal] = useRecoilState(isOpenModeModalState)
  const [isOpenLPShieldModal] = useRecoilState(isOpenLPShieldModalState)
  const { getPromoCodeAndStore } = usePromoCodes()

  const [errorToast, setErrorToast] = useRecoilState(errorToastState)

  // Hooks.
  const { user } = useUser()
  const { mutate: updateUser } = useUpdateUser()
  const updateUserForStatsig1 = updateUserForStatsig()
  const { initialized } = useContext(StatsigContext)
  const { status } = useSession()
  const { update } = useIntercom()

  const router = useRouter()
  const [enableGlobe, setEnableGlobe] = useState(false)
  const { data: teamResponse, isSuccess: isFetchTeamSuccess } =
    useGetActiveTeam('CUSTOM')
  const [, setActiveTeam] = useRecoilState(activeTeamState)
  const [game] = useRecoilState(selectedGameState)
  const [enableVoiceChatOffToggleButton] = useRecoilState(
    enableVoiceChatOffToggleButtonState,
  )

  useEffect(() => {
    if (teamResponse && isFetchTeamSuccess) {
      router.push(lobbyUrl)
    } else {
      setActiveTeam(undefined)
    }
  }, [teamResponse, isFetchTeamSuccess])

  useEffect(() => {
    update({
      hideDefaultLauncher: isMobile,
    })

    if (!isMobile) {
      setEnableGlobe(true)
    }
  }, [])

  useEffect(() => {
    if (
      enableVoiceChatOffToggleButton &&
      !isNil(user) &&
      user?.enableVoiceChat === null
    ) {
      updateUser({ enableVoiceChat: true })
    }
  }, [user, enableVoiceChatOffToggleButton])

  useEffect(() => {
    if (user && initialized) {
      try {
        updateUserForStatsig1({ userID: user.id?.toString() })
      } catch (e) {}
    }
  }, [user, initialized])

  useEffect(() => {
    if (errorToast.length > 0 && errorToast[0]) {
      toast.error(errorToast[0], {
        autoClose: false,
      })

      setErrorToast((oldErrorToasts) =>
        oldErrorToasts.filter((msg) => msg !== errorToast[0]),
      )
    }
  }, [errorToast])

  useEffect(() => {
    if (user) {
      if (!isNil(user.liveChatToken)) {
        update({
          userId: user.id?.toString(),
          userHash: user.liveChatToken,
        })
      } else {
        update({
          userId: user.id?.toString(),
        })
      }

      Cookies.set(UID_COOKIE, user.id?.toString())
    }
  }, [user])

  const clientQuery = useQueryClient()
  useEffect(() => {
    try {
      clientQuery.removeQueries([activeTeam, 'ALL'])
      // clientQuery.removeQueries([activeTeam, 'CUSTOM'])
    } catch (e) {}
  }, [])

  return (
    <div>
      {status === SessionStatusEnum.AUTHENTICATED && <HomePusherRequests />}
      {status === SessionStatusEnum.AUTHENTICATED && <HomeDeviceData />}
      {/*{status === SessionStatusEnum.AUTHENTICATED && (*/}
      {/*  <CustomVoiceChatExperiment />*/}
      {/*)}*/}

      {/* Main */}
      <div
        className={
          'relative mx-auto mt-0 flex flex-col items-center overflow-x-hidden overflow-y-hidden'
        }
      >
        {/*<div*/}
        {/*  className={'w-full cursor-pointer hidden md:flex'}*/}
        {/*  onClick={(e) => {*/}
        {/*    e.preventDefault()*/}
        {/*    router.push(balanceUrl)*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <HolidayBannerByGame />*/}
        {/*</div>*/}

        <HomePageHeader
          title={title}
          description={description ?? seoCategory?.seoH2}
        />

        <div className={'relative mt-5 w-full px-4 md:container md:px-0'}>
          <div className={' self-start text-[#F87B20] '}>
            <Divider backgroundColor={'border-bung-400'} />
          </div>

          <div
            className={' mb-3 mt-4 flex flex-row items-center justify-between '}
          >
            <h2 className={'text-body-medium text-[#F87B20]'}>Choose game</h2>
          </div>
        </div>

        <GameSelectorV3
          categories={categories?.data ?? []}
          gameTeammates={gameTeammates}
        />

        <div className={'relative mt-5 w-full px-2 md:container md:px-0'}>
          <div className={'z-10 flex w-full flex-row'}>
            <div
              className={
                'mx-2 mt-1 flex w-full flex-col sm:mx-20 md:mx-0 md:w-1/2 xl:w-1/2 '
              }
            >
              <div className={'z-20 flex w-full flex-col gap-3.5 opacity-95'}>
                <div
                  className={`flex flex-col gap-3.5 ${
                    status === SessionStatusEnum.AUTHENTICATED
                      ? 'visible'
                      : 'hidden'
                  }`}
                >
                  <h2 className={'text-body-medium self-start text-[#F87B20]'}>
                    Enter in-game info
                  </h2>

                  <div
                    className={'mr-2'}
                    onClick={(event) => {
                      event.preventDefault()
                      setInGameAccountFrom('modal')
                      setInGameAccountModalOpen(true)
                    }}
                  >
                    <InGameAccountSectionV2 profiles={profiles} />
                  </div>

                  <div className={'mr-2'}>
                    <Divider backgroundColor={'border-darul-500'} />
                  </div>
                </div>

                {/*<div*/}
                {/*  className={'w-full cursor-pointer'}*/}
                {/*  onClick={(e) => {*/}
                {/*    e.preventDefault()*/}
                {/*    router.push(balanceUrl)*/}
                {/*  }}*/}
                {/*>*/}
                {/*  <SeasonProduct />*/}
                {/*</div>*/}

                <h2 className={'text-body-medium self-start text-[#F87B20]'}>
                  Select mode
                </h2>

                {/*{game?.key === GameCategories.fortnite && (*/}
                {/*  <FortniteLeaderBanner />*/}
                {/*)}*/}

                {/*{game?.key === GameCategories.leagueOfLegends && (*/}
                {/*  <div className={'mt-0'}>*/}
                {/*    <ModeSelector />*/}
                {/*  </div>*/}
                {/*)}*/}

                {/*{selectedProductMode === modes[0] && (*/}
                {/*  <PlayMode gameTeammates={gameTeammates} />*/}
                {/*)}*/}
                {/*<PlayMode gameTeammates={gameTeammates} />*/}
                {/*{selectedProductMode === modes[1] && <AccountMode />}*/}
                <PlayMode gameTeammates={gameTeammates} />
              </div>
            </div>

            {/* Globe */}
            {enableGlobe && selectedProductMode === modes[0] && (
              <div className={'hidden md:flex md:w-1/2 xl:w-1/2 ml-5'}>
                <EarthAnimation gameTeammates={gameTeammates} />
              </div>
            )}

            {/*ml multiplication *2 */}
            {selectedProductMode === modes[1] &&
              game?.key === GameCategories.leagueOfLegends && (
                <div
                  className={`hidden md:flex ${
                    status === SessionStatusEnum.AUTHENTICATED
                      ? 'mt-36'
                      : 'mt-9'
                  } md:w-1/2 xl:w-1/2 ml-10`}
                >
                  <AccountInfoSection />
                </div>
              )}

            {user?.isGuest && (
              <div
                className={
                  'fixed bottom-0 right-0 z-20 flex w-full flex-col rounded-xl sm:mx-4 sm:w-96 md:absolute md:bottom-auto md:left-[51%] md:right-auto md:top-1 md:mx-0 md:w-fit xl:left-[51.5%]'
                }
              >
                <div className={'hidden md:flex'}>
                  <GuestNotification />
                </div>
              </div>
            )}
          </div>
        </div>

        {selectedProduct && (
          <div className={'container mt-24 flex flex-col gap-12 px-3 md:px-0'}>
            <Divider backgroundColor={'border-bung-400 mt-52'} />

            {selectedProduct.longFormDescription && (
              <DescriptionSection product={selectedProduct} />
            )}

            {selectedProduct.whatYouGet && (
              <BenefitSection product={selectedProduct} />
            )}

            <FeatureSection product={selectedProduct} />

            <HowItWorkSection product={selectedProduct} />

            <ReviewSection product={selectedProduct} />

            <FAQSection category={seoCategory} isHome={isHome} />

            {(user?.email || user?.id) && (
              <form className={'invisible pointer-events-none'}>
                <input
                  type='text'
                  id='email'
                  disabled={true}
                  defaultValue={user?.email ?? ''}
                />

                <input
                  type='text'
                  id='external_id'
                  disabled={true}
                  defaultValue={user?.id ?? ''}
                />
              </form>
            )}
          </div>
        )}
      </div>

      {isOpenInGameAccountModal && (
        <InGameAccountModal profiles={profiles} getProfiles={refetchProfiles} />
      )}

      {isOpenCompensateModal.isOpen && <CompensateModal />}

      {isOpenLimitedTimeOfferModal && <LimitedTimeOfferModal />}

      {isOpenCoaching && <CoachingInfoModal />}

      {isOpenModeModal && <ProductModeInfoModal />}

      {isOpenLPShieldModal && <LPShieldModal />}
    </div>
  )
}
